import React, { useEffect } from 'react';
import { baseUrl } from '../constants';
import axios from 'axios';
import Button from '@mui/material/Button';
import { useGoogleLogin } from '@react-oauth/google';
import HeaderComponent from '../components/HeaderComponent';
import WelcomeComponent from '../components/WelcomeComponent';
import Pose from '../assets/Pose_02.png';
import Planet from '../assets/planet_7.png';
import { useState } from 'react';
import LoginComponent from '../components/LoginComponent';
import { useDispatch } from 'react-redux';
import FpComponent from '../components/FpComponent';
import { useNavigate, useLocation } from 'react-router-dom';
import { getClassHome, getClassProgress } from '../redux/actions/getClassHome';
import {
  getDashboardData,
  getRankings,
  getScheduleData,
} from '../redux/actions/getDashboard';
import { useTranslation } from 'react-i18next';
import googleicon from '../assets/btn_google_signin_dark_focus_web.png';
import { Link } from 'react-router-dom';

const Welcome = () => {
  const { t } = useTranslation();
  const [, setType] = useState('');
  const [, setLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const [fp, setFp] = useState(false);
  const dispatch = useDispatch();
  const moment = require('moment-timezone');
  const user = localStorage.getItem('user_data');
  const navigate = useNavigate();
  const { state } = useLocation();
  var msg = '';
  useEffect(() => {
    console.log('FO::', fp, open);
  }, [fp, open]);
  useEffect(() => {
    navigate('/login/physio');
    if (user) {
      let { role } = JSON.parse(user);
      console.log('Role::', JSON.parse(user));
      if (role === 'teacher') {
        //call all the teacher apis
        navigate('/teacherhome', { state: { role: 'teacher' } });
      } else if (role === 'superadmin') {
        navigate('/admin-dashboard', { state: { role: 'superadmin' } });
      } else {
        //call all the stud apis
        navigate('/home');
      }
    } else {
      localStorage.clear();
      dispatch({
        type: 'LOGOUT',
        payload: {},
      });
    }
  }, []);
  const Gnavigate = async () => {
    if (msg === 'Login Successfull') {
      console.log('msg1');
      if (state && state.from === 'exercise') navigate('/exercise');
      else {
        let { role } = JSON.parse(localStorage.getItem('user_data'));
        if (role === 'teacher') {
          navigate('/teacherhome', { state: { role: 'teacher' } });
        } else if (role === 'superadmin')
          navigate('/admin-dashboard', { state: { role: 'superadmin' } });
        else navigate('/home');
      }
    }
  };
  const googleSignOn = async (guser) => {
    await axios
      .get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${guser.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${guser.access_token}`,
            Accept: 'application/json',
          },
        }
      )
      .then((res) => {
        console.log('res', baseUrl, res.data);
        axios
          .post(`${baseUrl}/google-sign-on`, {
            email: res.data.email,
            id: res.data.id,
            verified_email: res.data.verified_email,
          })
          .then(async (response) => {
            // console.log('Status is ::' + JSON.stringify(response) );
            if (
              response.data.status === 404 &&
              response.data.message === 'User Not Found!'
            ) {
              window.alert(response.data.message);
            } else if (response.data.status === 200) {
              console.log('Status is ::' + JSON.stringify(response));
              dispatch({
                type: 'LOGIN',
                payload: response.data,
              });
              await localStorage.setItem(
                'user_data',
                JSON.stringify(response.data.user)
              );
              await localStorage.setItem('user_name', response.data.user.name);
              await localStorage.setItem('token', response.data.token);
              await localStorage.setItem(
                'full_user',
                JSON.stringify(response.data.user)
              );
              dispatch({ type: 'USER', payload: response.data.user });
              await getDashboardData(dispatch);
              if (response.data.user.role === 'teacher') {
                await getClassProgress(dispatch, (a) => a);
                await getClassHome(dispatch);
                msg = 'Login Successfull';
                await getScheduleData(
                  dispatch,
                  moment().format('YYYY-MM-DD'),
                  (some) => some
                );
                setType('success');
                setLoad(false);
              } else {
                msg = 'Login Successfull';
                await getScheduleData(
                  dispatch,
                  moment().format('YYYY-MM-DD'),
                  (some) => some
                );
                await getDashboardData(dispatch);
                await getRankings(dispatch, (a) => a);
                setType('success');
                setLoad(false);
              }

              Gnavigate();
            }
          })
          .catch((err) => {
            console.log('err', err);
          });
        // console.log("err", err)
      });
  };
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      if (codeResponse) {
        googleSignOn(codeResponse);
      }
    },
    onError: (error) => console.log('Login Failed:', error),
  });
  return (
    <div className="welcome-page-container">
      <img src={Pose} className="login-bcg-img-1" alt="Clinikal" />
      <img src={Planet} className="login-bcg-img-2" alt="Clinikal" />

      {open && <LoginComponent open={open} setOpen={setOpen} setFp={setFp} />}
      {fp && <FpComponent fp={fp} setFp={setFp} setOpen={setOpen} />}
      <HeaderComponent pageName={'welcome'} />
      <WelcomeComponent />

      <div className="welcome-text">
        <h1>{t('welcomeView.welcome')}</h1>
        {/* Sign In Button */}
        <Button
          variant="contained"
          className="welcome-login-btn"
          sx={{
            borderRadius: 20,
            backgroundColor: '#FF592C',
          }}
          onClick={() => setOpen(true)}
        >
          <center>{t('welcomeView.login')}</center>
        </Button>
        <div>
          <p style={{ width: '70px', color: 'black', textAlign: 'center' }}>
            OR
          </p>
        </div>
        {/* Google Button */}
        <Button
          sx={{
            borderRadius: 20,
          }}
          onClick={() => login()}
        >
          <img src={googleicon} alt="img" border="0" />
        </Button>
        <div
          className="signin-existing"
          style={{ position: 'relative', color: 'black' }}
        >
          <p>
            {' '}
            <br />
            <Link to="/signup">{t('signUpComponent.sign-up-here')}</Link>{' '}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
