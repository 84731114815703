import Grid from '@mui/material/Grid';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { OPTIMAL_GAIT_CYCLE_PCT } from '../../../../../constants/Demo/gaitAnalysis';
import Headline from './Headline';
import { MetricsItem } from './ResultsMetrics';

export default function CycleChart({ chartKey, metrics }) {
  if (!metrics) {
    return <div>Loading...</div>;
  }

  const series = [
    {
      name: 'Stance',
      data: [
        {
          x: 'left',
          y: metrics.stanceTimeLeft * 100,
        },
        {
          x: 'right',
          y: metrics.stanceTimeRight * 100,
        },
      ],
    },
    {
      name: 'Swing',
      data: [
        {
          x: 'left',
          y: metrics.strideTimeLeft * 100,
          goals: [
            {
              name: 'Expected',
              value: OPTIMAL_GAIT_CYCLE_PCT * 100,
              strokeWidth: 5,
              strokeHeight: 80,
              strokeColor: '#f1a60c',
            },
          ],
        },
        {
          x: 'right',
          y: metrics.strideTimeRight * 100,
          goals: [
            {
              name: 'Expected',
              value: OPTIMAL_GAIT_CYCLE_PCT * 100,
              strokeWidth: 5,
              strokeHeight: 80,
              strokeColor: '#f1a60c',
            },
          ],
        },
      ],
    },
  ];

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      stackType: '100%',
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    xaxis: {
      categories: ['left', 'right'],
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toFixed(2);
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'bottom',
      // horizontalAlign: 'left',
      // offsetX: 40,
      customLegendItems: ['Stance', 'Swing', 'Expected'],
    },
  };

  return (
    <Grid>
      <Grid item xs={12} sx={{ m: '3% 25% 2% 25%' }}>
        <Headline text={'Gait Cycle %'} height={'5vh'} />
      </Grid>
      <Grid item xs={12} sx={itemStyle}>
        <ReactApexChart
          key={chartKey}
          options={options}
          series={series}
          type="bar"
          height={'200%'}
          width={'200%'}
        />
      </Grid>
      <Grid item xs={12} sx={{ ...itemStyle, marginTop: 2 }}>
        Gait cycle variability: {metrics.gaitCycleVariability}%
      </Grid>
      <Grid item xs={12} sx={itemStyle}>
        Gait cycle optimum deviation: {metrics.gaitCycleMeanOptimumDeviation}%
      </Grid>
      <Grid item xs={24} sx={{ m: '3% 25% 5% 25%' }}>
        <MetricsItem
          title="Cadence steps/min"
          value={`${metrics.cadence}  [120]`}
          titleFontSize={16}
        />
      </Grid>
      <Grid item xs={24} sx={{ m: '3% 25% 5% 25%' }}>
        <MetricsItem
          title="Velocity m/s"
          value={metrics.walkingSpeed}
          titleFontSize={16}
        />
      </Grid>
    </Grid>
  );
}

const itemStyle = {
  width: '100%',
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
};
