import React from 'react';
import {
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import Backdrop from '@mui/material/Backdrop';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import success from '../../../assets/sucess.png';
import wrong from '../../../assets/wrong.png';
import Modal from '@mui/material/Modal';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';
import { registerWithEmailAndPassword } from '../../../utilities/Demo/auth';

const ConfirmAllDetails = (props) => {
  const { prevStep, open, setOpen, name, email, password, birthdate } = props;
  const { t } = useTranslation();
  const [load, setLoad] = useState(false);
  const [show, setShow] = useState(false);
  const [result, setResult] = useState('');
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();
  const { loginTarget } = useParams();
  const style = {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    margin: 'auto',
    height: '70vh',
    width: '70vw',
    bgcolor: 'white',
    borderRadius: '2.5em',
    p: 0,
  };
  const closeModal = () => {
    setTimeout(() => {
      navigate('/', { replace: true });
    }, 3000);
    setShow(false);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoad(true);
    const err = await registerWithEmailAndPassword(
      email,
      password,
      name,
      birthdate
    );
    setShow(true);
    if (!err) {
      setLoad(false);
      setResult('success');
      setMsg('Signed up Successfully, please confirm your email!');
      setTimeout(() => {
        window.location.href = '/all_patients';
      }, 1000);
    } else {
      setResult('error');
      setLoad(false);
      console.log(err.message);
      if (err.message === 'Firebase: Error (auth/email-already-in-use).') {
        setMsg('Email already exists');
      } else {
        setMsg('Something went wrong');
      }
    }
  };
  const backPrevious = () => {
    // e.preventDefault();
    prevStep();
  };
  return (
    <Box>
      <Dialog
        PaperProps={{
          sx: {
            borderRadius: 10,
            maxheight: '53%',
            width: '95vw',
            padding: 2,
          },
        }}
        scroll="body"
        BackdropComponent={styled(Backdrop, {
          name: 'MuiModal',
          slot: 'Backdrop',
          overridesResolver: (props, styles) => {
            return styles.backdrop;
          },
        })({
          zIndex: -1,
          backgroundColor: 'rgb(242, 247, 247,0.5)',
        })}
        open={open}
        onClose={() => setOpen(false)}
      >
        <form>
          <DialogContent style={{ overflow: 'hidden' }}>
            <DialogTitle sx={{ fontSize: 18 }}>
              {t('signUpComponent.confirm')}
              <span
                style={{ float: 'right', color: 'grey', cursor: 'pointer' }}
                onClick={() => setOpen(false)}
              >
                &#x2715;
              </span>
            </DialogTitle>
            <List>
              <ListItem>
                <ListItemText primary="Name" secondary={name} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Email" secondary={email} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Birthdate" secondary={birthdate} />
              </ListItem>
            </List>
          </DialogContent>
          <DialogActions
            disableSpacing={true}
            sx={{ justifyContent: 'center', height: '10%' }}
          >
            <div className="form-grp">
              {load ? (
                <CircularProgress
                  size={42}
                  color="success"
                  sx={{ justifyContent: 'center' }}
                />
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 20,
                    backgroundColor: '#FF592C',
                    marginLeft: '5%',
                  }}
                  onClick={handleSignUp}
                >
                  {t('signUpComponent.signup')}
                </Button>
              )}
            </div>

            <Button
              // type="submit"
              variant="contained"
              sx={{
                borderRadius: 20,
                backgroundColor: '#FF592C',
                marginLeft: '5%',
              }}
              onClick={() => backPrevious()}
            >
              {t('signUpComponent.back')}
            </Button>
            <Tooltip title="Please review your details carefully!">
              <span>
                <ErrorIcon
                  style={{ float: 'centre', color: 'green', cursor: 'pointer' }}
                />
              </span>
            </Tooltip>
          </DialogActions>
        </form>
        <Modal
          open={show}
          onClose={() => closeModal()}
          height={'100vh'}
          sx={{
            justify: 'center',
            margin: 'auto',
            pt: '10rem',
            border: 'none',
          }}
          bordered={false}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={style} border={'none'}>
            {result != 'error' ? (
              <>
                <img className="success-img" src={success}></img>
                <h2 id="parent-modal-title">{t('signUpComponent.signup')}</h2>
                <div
                  id="parent-modal-description"
                  style={{ display: 'flex', width: '40vw' }}
                >
                  <div
                    className="mod_data"
                    style={{
                      display: 'flex',
                      padding: '15% 1%',
                      width: '100%',
                      fontSize: '1rem',
                      fontWeight: 'inherit',
                    }}
                  >
                    <span>
                      {t('signUpComponent.successmsg')}
                      <Link to={'/login/' + loginTarget || 'physio'}>
                        {t('signUpComponent.sign-here')}
                      </Link>
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <img className="success-img" src={wrong}></img>
                <h2 id="parent-modal-title">{t('signUpComponent.failed')}</h2>

                <div className="mod_data">
                  <center> {msg}</center>
                </div>
              </>
            )}
          </Box>
        </Modal>
      </Dialog>
    </Box>
  );
};

export default ConfirmAllDetails;
